(() => {

    const bigCursor = document.querySelector('.cursor-big');
    const smallCursor = document.querySelector('.cursor-small');
    const btn = document.querySelectorAll('.button');
    
    /**** cursor ****/
    
    document.addEventListener('mousemove', e => {
        
        let x = e.clientX;
        let y = e.clientY;
    
        bigCursor.style.top = y + 'px';
        bigCursor.style.left = x + 'px';
    
        smallCursor.style.top = y + 'px';
        smallCursor.style.left = x + 'px';
    })
    
    document.addEventListener('click', e => {
    
        let x = e.clientX;
        let y = e.clientY;
    
        bigCursor.classList.add('expand');
    
        bigCursor.style.top = y + 'px';
        bigCursor.style.left = x + 'px';
    
        setTimeout(() => {
            bigCursor.classList.remove('expand');
    
            bigCursor.style.top = y + 'px';
            bigCursor.style.left = x + 'px';
        
        }, 300);
    
    
    })
    
    /**** links ****/
    
    const links = document.querySelectorAll('a');
    const h1 = document.querySelectorAll('h1');
    
    const texts = [...links, ...h1];
    
    texts.forEach(text => {
        text.addEventListener('mouseover', () => {
            smallCursor.classList.add('expand');
            bigCursor.classList.add('unset');
        })
        text.addEventListener('mouseout', () => {
            smallCursor.classList.remove('expand');
            bigCursor.classList.remove('unset');
        })
    })
    
    /**** button ****/
    
    btn.forEach(button => {
    
        const svg = button.querySelectorAll('g');
    
        button.addEventListener('mouseover', () => {
            button.classList.add('active');
            svg.forEach(g => {
                g.setAttribute("fill", "white");
            })
    
        })
        button.addEventListener('mouseout', () => {
            button.classList.remove('active');
            svg.forEach(g => {
                g.setAttribute("fill", "black");
            })
        })
    });
    
})();
